import { bindActionCreators } from 'redux';
import * as actionsBlacklist from '../../action/blacklist/actions';

const mapStateToProps = state => {
    return {
        callcenterBlacklist: state.blacklistReducer,
    };
};

const mapDispatchToProps = (dispatch, { callcenterAPI }) => {
    const {
        blacklistSetPhone,
        blacklistSetBlacklistRemoved,
        blacklistSaveError,
        blacklistSetLoading,
    } = bindActionCreators(actionsBlacklist, dispatch);

    const blacklistRemove = phone => {
        blacklistSetLoading(true);
        blacklistSetPhone(phone);

        callcenterAPI
            .removeFromBlacklist(phone)
            .then(data => {
                blacklistSetBlacklistRemoved(data);
            })
            .catch(e => {
                blacklistSaveError(e);
            });
    };

    return {
        blacklistRemove,
    };
};

export { mapStateToProps, mapDispatchToProps };
