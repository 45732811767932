import { bindActionCreators } from 'redux';
import * as actionsBlacklist from '../../action/blacklist/actions';
import { blacklistSetBlacklisted } from '../../action/blacklist/actions';

const mapStateToProps = state => {
    return {
        callcenterBlacklist: state.blacklistReducer,
    };
};

const mapDispatchToProps = (dispatch, { callcenterAPI }) => {
    const {
        blacklistClear,
        blacklistSave,
        blacklistSaveError,
        blacklistSetLoading,
        blacklistSetPhone,
    } = bindActionCreators(actionsBlacklist, dispatch);

    const blacklistFetch = phone => {
        blacklistSetLoading(true);
        blacklistSetPhone(phone);

        callcenterAPI
            .doSearchInBlacklist(phone)
            .then(data => {
                blacklistSave(data);
            })
            .catch(e => {
                blacklistSaveError(e);
            });
    };

    const blacklistAdd = (phone, comment) => {
        callcenterAPI
            .addToBlacklist(phone, comment)
            .then(data => {
                blacklistSetBlacklisted(true);
            })
            .catch(e => {
                blacklistSaveError(e);
            });
    };

    return {
        blacklistFetch,
        blacklistAdd,
        blacklistClear,
    };
};

export { mapStateToProps, mapDispatchToProps };
