export const blacklistSave = blacklistItems => ({
    type: 'BLACKLIST_SAVE',
    blacklistItems,
});
export const blacklistSetPhone = phone => ({
    type: 'BLACKLIST_SET_PHONE',
    phone,
});
export const blacklistSaveError = error => ({ type: 'SAVE_ERROR', error });
export const blacklistSetLoading = (isLoading = true) => ({
    type: 'BLACKLIST_SET_LOADING',
    isLoading,
});
export const blacklistSetBlacklisted = (isBlacklisted = true) => ({
    type: 'BLACKLIST_SET_BLACKLISTED',
    isBlacklisted,
});
export const blacklistSetBlacklistRemoved = (isBlacklistRemoved = true) => ({
    type: 'BLACKLIST_SET_REMOVE',
    isBlacklistRemoved,
});
export const blacklistClear = () => ({ type: 'BLACKLIST_CLEAR' });
