const initialState = {
    blacklistItems: null, // результаты поиска
    isLoading: false, // данные подгружаются
    isGreetingMessage: true, //пользователь первый раз на странице
    isBlacklisted: false, // телефон добавляется в ЧС
    isBlacklistRemoved: false, // телефон удаляется из ЧС
    isEmptySearch: false, // пустой результат поиска
    error: null, // ошибка, если есть
    phone: null, // телефон, над которым происходит операция
};

const blacklistReducer = (state, action) => {
    switch (action.type) {
        case 'BLACKLIST_SAVE':
            const isEmptySearch =
                !action.blacklistItems || action.blacklistItems.length === 0;
            return {
                blacklistItems: action.blacklistItems,
                isLoading: false,
                isGreetingMessage: false,
                isBlacklisted: false,
                isEmptySearch: isEmptySearch,
                isBlacklistRemoved: false,
                error: null,
                phone: state.phone,
            };

        case 'BLACKLIST_SET_PHONE':
            return {
                blacklistItems: state.blacklistItems,
                isLoading: false,
                isGreetingMessage: false,
                isBlacklisted: false,
                isEmptySearch: false,
                isBlacklistRemoved: false,
                error: null,
                phone: action.phone,
            };

        case 'BLACKLIST_SET_LOADING':
            return {
                blacklistItems: state.blacklistItems,
                isLoading: true,
                isGreetingMessage: false,
                isBlacklisted: false,
                isEmptySearch: false,
                isBlacklistRemoved: false,
                error: null,
                phone: state.phone,
            };

        case 'BLACKLIST_SET_BLACKLISTED':
            return {
                blacklistItems: null,
                isLoading: false,
                isGreetingMessage: false,
                isBlacklisted: true,
                isEmptySearch: false,
                isBlacklistRemoved: false,
                error: null,
                phone: state.phone,
            };

        case 'BLACKLIST_SET_REMOVE':
            return {
                blacklistItems: null,
                isLoading: false,
                isGreetingMessage: false,
                isBlacklisted: false,
                isBlacklistRemoved: true,
                isEmptySearch: false,
                error: null,
                phone: state.phone,
            };

        case 'BLACKLIST_SAVE_ERROR':
            return {
                blacklistItems: state.blacklistItems,
                isLoading: false,
                isGreetingMessage: false,
                isBlacklisted: false,
                isEmptySearch: false,
                isBlacklistRemoved: false,
                error: action.error,
                phone: state.phone,
            };

        case 'BLACKLIST_CLEAR':
        default:
            return initialState;
    }
};

export default blacklistReducer;
