export default class CallcenterApi {
    constructor(email, password) {
        this.email = email;
        this.password = password;
    }

    _getResource = async url => {
        let headers = new Headers();
        headers.set(
            'Authorization',
            `Basic ${Buffer.from(`${this.email}:${this.password}`).toString(
                'base64',
            )}`,
        );
        const response = await fetch(`${this._endpoint}${url}`, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            throw new Error(
                `Could not fetch ${url}, received ${response.status}`,
            );
        }
        return await response.json();
    };

    _deleteResource = async (url, body) => {
        let headers = new Headers();
        headers.set(
            'Authorization',
            `Basic ${Buffer.from(`${this.email}:${this.password}`).toString(
                'base64',
            )}`,
        );
        const response = await fetch(`${this._endpoint}${url}`, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            throw new Error(
                `Could not fetch ${url}, received ${response.status}`,
            );
        }
        return null;
    };

    _postResource = async (url, body) => {
        let headers = new Headers();
        headers.set(
            'Authorization',
            `Basic ${Buffer.from(`${this.email}:${this.password}`).toString(
                'base64',
            )}`,
        );
        const response = await fetch(`${this._endpoint}${url}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            throw new Error(
                `Could not fetch ${url}, received ${response.status}`,
            );
        }
        return await response.json();
    };

    _endpoint = 'https://callcenter.clickwap.dev/api/v2';
    // _endpoint = 'http://127.0.0.1:8080/api/v2';

    authenticate = async () => {
        let headers = new Headers();
        headers.set(
            'Authorization',
            `Basic ${Buffer.from(`${this.email}:${this.password}`).toString(
                'base64',
            )}`,
        );

        const url = `${this._endpoint}/call_center_history?offset=0&limit=1`;
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });

        switch (response.status) {
            case 200:
                return true;
            case 401:
                return false;
            default:
                throw new Error(
                    `Could not fetch ${url}, received ${response.status}`,
                );
        }
    };

    getBlacklist = async (offset, limit) => {
        const url = `/blacklist?offset=${offset}&limit=${limit}`;
        return await this._getResource(url);
    };

    checkBlacklist = async msisdn => {
        const url = `/blacklist/${msisdn}`;
        return await this._getResource(url);
    };

    setLogin = login => {
        this.email = login;
    };

    setPassword = password => {
        this.password = password;
    };

    addToBlacklist = async (phone, comment) => {
        const url = `/blacklist/${phone}`;
        let headers = new Headers();

        headers.set(
            'Authorization',
            `Basic ${Buffer.from(`${this.email}:${this.password}`).toString(
                'base64',
            )}`,
        );
        const response = await fetch(`${this._endpoint}${url}`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ comment: comment }),
        });

        if (!response.ok) {
            throw new Error(
                `Could not fetch ${url}, received ${response.status}`,
            );
        }
        return null;
    };

    addToBlacklistMultiply = async (msisdns, comment) => {
        const url = `/blacklist/list`;
        return await this._postResource(url, {
            msisdns: msisdns,
            comment: comment,
        });
    };

    removeFromBlacklist = async phone => {
        const url = `/blacklist/${phone}`;
        return await this._deleteResource(url, null);
    };

    getSubscription = async (offset, limit) => {
        const url = `/subscriptions?offset=${offset}&limit=${limit}`;
        return await this._getResource(url);
    };

    doUnsubscribe = async (subscription_id, comment) => {
        const url = `/subscriptions/${subscription_id}`;
        return await this._deleteResource(url, { comment: comment });
    };

    doSearch = async phone => {
        const url = `/search?msisdn=${phone}`;
        return await this._getResource(url);
    };

    doSearchInBlacklist = async phone => {
        const url = `/blacklist/${phone}`;
        return await this._getResource(url);
    };

    getHistory = async (offset, limit) => {
        const url = `/call_center_history?offset=${offset}&limit=${limit}`;
        return await this._getResource(url);
    };
}
