import { Container } from 'semantic-ui-react';
import React from 'react';
import TableSearch from '../table-search/table';
import SearchInput from '../buttonpanel-search/buttonpanel-search';
import MessageAlreadyInBlacklist from '../message-already_in_blacklist';
import MessageJustUseSearch from '../message-just_use_search';
import MessageSearchNotFound from '../message-search_not_found';
import MessageUnsubscribe from '../message-unsubscribe';

export default class Search extends React.Component {
    render() {
        return (
            <Container style={{ marginTop: '4em' }}>
                <Container textAlign={'center'}>
                    <SearchInput style={{ margin: 'auto' }} />
                </Container>
                <MessageUnsubscribe />
                <MessageJustUseSearch />
                <MessageSearchNotFound />
                <MessageAlreadyInBlacklist />
                <TableSearch />
            </Container>
        );
    }
}
