export const searchSave = searchItems => ({
    type: 'SAVE_SEARCH',
    searchItems,
});
export const searchSetPhone = phone => ({
    type: 'SET_PHONE',
    phone,
});
export const searchSaveError = error => ({ type: 'SAVE_ERROR', error });
export const searchSetLoading = (isLoading = true) => ({
    type: 'SET_SEARCH_LOADING',
    isLoading,
});
export const searchClear = () => ({ type: 'CLEAR_SEARCH' });
export const searchSetUnsubscribed = (isUnsubscribed = true) => ({
    type: 'SET_UNSUBSCRIBED',
    isUnsubscribed,
});
