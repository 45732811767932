import { bindActionCreators } from 'redux';
import * as actionsMultiplyBlacklist from '../../action/multiply-blacklist/actions';

const mapStateToProps = state => {
    return {
        callcenterBlacklistMultiply: state.blacklistMultiplyReducer,
    };
};

const mapDispatchToProps = (dispatch, { callcenterAPI }) => {
    const {
        blacklistSetVisible,
        blacklistSave,
        blacklistSetLoading,
        blacklistSaveError,
    } = bindActionCreators(actionsMultiplyBlacklist, dispatch);

    const blacklistMultiply = (phoneStructs, comment) => {
        blacklistSetLoading(true);
        const msisdns = phoneStructs.map(phoneStruct => {
            return phoneStruct.msisdn.toString();
        });

        callcenterAPI
            .addToBlacklistMultiply(msisdns, comment)
            .then(blacklistItems => {
                blacklistSave(blacklistItems);
            })
            .catch(e => {
                blacklistSaveError(e);
            });
    };

    return {
        blacklistSave,
        blacklistMultiply,
        blacklistSetVisible,
    };
};

export { mapStateToProps, mapDispatchToProps };
