import React from 'react';
import { Button, Icon, Input } from 'semantic-ui-react';
import { withCallcenterAPI } from '../callcenter-api';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './dispatch';

class ButtonpanelSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: null,
        };
    }
    onChangePhone = event => {
        this.setState({ phone: event.target.value });
    };

    onClickSearch = () => {
        const { phone } = this.state;
        this.props.searchSetPhone(phone);
        this.props.searchFetch(phone);
    };

    render() {
        const { isLoading } = this.props.callcenterSearch;
        const isSearchDisable = !this.state.phone;

        return (
            <Button.Group>
                <Button color={'twitter'}>
                    <Input
                        placeholder="71321234567"
                        onChange={this.onChangePhone}
                    />
                </Button>

                <Button
                    icon
                    loading={isLoading}
                    disabled={isSearchDisable}
                    labelPosition="right"
                    color={'twitter'}
                    onClick={this.onClickSearch}
                >
                    <Icon name="search" />
                    Поиск
                </Button>
            </Button.Group>
        );
    }
}

export default withCallcenterAPI(
    connect(mapStateToProps, mapDispatchToProps)(ButtonpanelSearch),
);
