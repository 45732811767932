import { Label } from 'semantic-ui-react';
import React from 'react';

const LabelStatusMultiplyBlacklist = status => {
    let result;

    switch (status) {
        case -1:
            result = (
                <Label
                    color={'violet'}
                    title={
                        'запрос еще не отправлен, поэтому состояние номера неизвестно'
                    }
                >
                    Неизвестно
                </Label>
            );
            break;
        case 0:
            result = (
                <Label
                    color={'green'}
                    title={'номер успешно добавлен в чёрный список'}
                >
                    Добавлен
                </Label>
            );
            break;
        case 1:
            result = (
                <Label color={'blue'} title={'номер телефона некорректный'}>
                    Некорректный
                </Label>
            );
            break;
        case 2:
            result = (
                <Label
                    color={'olive'}
                    title={'не удалось определить оператора'}
                >
                    Неизвестный оператор
                </Label>
            );
            break;
        case 3:
            result = (
                <Label color={'red'} title={'не удалось обновить данные в БД'}>
                    Ошибка
                </Label>
            );
            break;

        case 4:
            result = (
                <Label
                    color={'red'}
                    title={'не удалось добавить в историю коллцентра'}
                >
                    Ошибка
                </Label>
            );
            break;

        case 5:
            result = (
                <Label
                    color={'red'}
                    title={'не удалось отписать подписки по номеру'}
                >
                    Ошибка
                </Label>
            );
            break;

        case 6:
            result = (
                <Label
                    color={'red'}
                    title={'не удалось получить подписки по номеру телефона'}
                >
                    Ошибка
                </Label>
            );
            break;
        default:
            result = <Label>Не известно</Label>;
    }
    return result;
};

export default LabelStatusMultiplyBlacklist;
