import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'semantic-ui-react';
import ButtonUnsubscribe from '../button-unsubscribe';
import LabelStatusSubscription from '../label-status_subscription/label-status-subscription';
import { formatDateString } from '../../services/utils/date';
import { withCallcenterAPI } from '../callcenter-api';
import { mapDispatchToProps, mapStateToProps } from './dispatch';
import MessageError from '../message-error';

class TableSearch extends React.Component {
    tableBody = data => {
        if (!data) {
            return null;
        }

        const rows = data.map(subscription => {
            return (
                <Table.Row key={subscription.sub_id}>
                    <Table.Cell>{subscription.msisdn}</Table.Cell>
                    <Table.Cell>{subscription.description}</Table.Cell>
                    <Table.Cell>{subscription.sub_id}</Table.Cell>
                    <Table.Cell>
                        {formatDateString(subscription.created_at)}
                    </Table.Cell>
                    <Table.Cell>
                        {formatDateString(subscription.updated_at)}
                    </Table.Cell>
                    <Table.Cell>
                        <LabelStatusSubscription status={subscription.status} />
                    </Table.Cell>
                    <Table.Cell textAlign={'center'}>
                        {subscription.status === 'sub_active' ||
                        subscription.status === 'sub_suspended' ? (
                            <ButtonUnsubscribe
                                onDataUpdate={this.onDataUpdate}
                                sub_id={subscription.sub_id}
                            />
                        ) : null}
                    </Table.Cell>
                </Table.Row>
            );
        });

        return <Table.Body> {rows} </Table.Body>;
    };

    render() {
        const { searchItems, error } = this.props.callcenterSearch;

        if (!!error) {
            return MessageError(error);
        }
        if (!searchItems) return null;

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign={'center'}>
                            Телефон
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={'center'}>
                            Проект
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={'center'}>
                            id Подписки
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={'center'}>
                            Дата подписки
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={'center'}>
                            Дата последнего изменения
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={'center'}>
                            Статус
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={'center'}>
                            Операции
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                {this.tableBody(searchItems)}
            </Table>
        );
    }
}

export default withCallcenterAPI(
    connect(mapStateToProps, mapDispatchToProps)(TableSearch),
);
