import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Modal, TextArea } from 'semantic-ui-react';
import { withCallcenterAPI } from '../callcenter-api';
import { mapDispatchToProps, mapStateToProps } from './dispatch';

class ButtonUnsubscribe extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: 'Причина отписки не задана',
        };
    }

    onClickHandler = () => {
        const { sub_id } = this.props;
        const { comment } = this.state;
        this.props.doUnsubscribe(sub_id, comment);
    };

    onChangeComment = event => {
        this.setState({
            comment: event.target.value,
        });
    };

    render() {
        return (
            <Modal
                trigger={
                    <Button
                        icon
                        labelPosition="left"
                        size={'tiny'}
                        color={'twitter'}
                    >
                        <Icon size={'large'} name="thumbs down outline" />
                        Отписать
                    </Button>
                }
                header="Отписать!"
                size={'small'}
                content={
                    <TextArea
                        style={{
                            minWidth: '90%',
                            marginLeft: '5%',
                            marginRight: '5%',
                        }}
                        placeholder="Причина отписки"
                        onChange={this.onChangeComment}
                    />
                }
                actions={[
                    { key: 'dismiss', content: 'Отмена', positive: false },
                    {
                        key: 'done',
                        content: 'Отписать',
                        positive: true,
                        onClick: this.onClickHandler,
                    },
                ]}
            />
        );
    }
}

export default withCallcenterAPI(
    connect(mapStateToProps, mapDispatchToProps)(ButtonUnsubscribe),
);
