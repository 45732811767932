import { bindActionCreators } from 'redux';
import * as actionsSearch from '../../action/search/actions';

const mapStateToProps = state => {
    return {
        callcenterSearch: state.searchReducer,
    };
};

const mapDispatchToProps = (dispatch, { callcenterAPI }) => {
    const {
        searchClear,
        searchSave,
        searchSaveError,
        searchSetLoading,
        searchSetPhone,
    } = bindActionCreators(actionsSearch, dispatch);

    const searchFetch = phone => {
        searchSetLoading(true);

        callcenterAPI
            .doSearch(phone)
            .then(data => {
                searchSave(data);
            })
            .catch(e => {
                searchSaveError(e);
            });
    };

    return {
        searchSetPhone,
        searchFetch,
        searchClear,
    };
};

export { mapStateToProps, mapDispatchToProps };
