import { bindActionCreators } from 'redux';
import * as actionsMultiplyBlacklist from '../../action/multiply-blacklist/actions';

const mapStateToProps = state => {
    return {
        callcenterBlacklistMultiply: state.blacklistMultiplyReducer,
    };
};

const mapDispatchToProps = (dispatch, { callcenterAPI }) => {
    const { blacklistSetVisible } = bindActionCreators(
        actionsMultiplyBlacklist,
        dispatch,
    );

    return {
        blacklistSetVisible,
    };
};

export { mapStateToProps, mapDispatchToProps };
