import React from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { mapStateToProps } from './dispatch';

const MessageSearchNotFound = props => {
    const { phone, isEmptySearch } = props.callcenterSearch;

    if (isEmptySearch) {
        return (
            <Message
                info
                icon="frown outline"
                header={`Подписки по номеру ${phone} не найдены`}
                content="Абонент не пользовался услугами мобильных подписок"
            />
        );
    }

    return null;
};

export default connect(mapStateToProps)(MessageSearchNotFound);
