import { Label } from 'semantic-ui-react';
import React from 'react';

const LabelHistoryOperation = ({ operation }) => {
    let result;

    switch (operation) {
        case 'added_to_blacklist':
            result = <Label color={'orange'}>Добавление в ЧС</Label>;
            break;
        case 'delete_blacklist':
            result = <Label color={'blue'}>Удаление из ЧС</Label>;
            break;
        case 'unsub_from_call_center':
            result = <Label color={'purple'}>Отписка</Label>;
            break;
        default:
            result = <Label>Не известно</Label>;
    }
    return result;
};

export default LabelHistoryOperation;
