import { bindActionCreators } from 'redux';
import * as actionsHistory from '../../action/history/actions';

const mapStateToProps = state => {
    return {
        callcenterHistory: state.historyReducer,
    };
};

const mapDispatchToProps = (dispatch, { callcenterAPI }) => {
    // история операций в callcenter
    const {
        historySave,
        historySaveError,
        historySetLoading,
        historySetLimit,
        historySetOffset,
    } = bindActionCreators(actionsHistory, dispatch);

    const historyFetch = (offset, limit) => {
        historySetLoading(true);

        callcenterAPI
            .getHistory(offset, limit)
            .then(data => {
                historySave(data);
            })
            .catch(e => {
                historySaveError(e);
            });
    };

    return {
        historyFetch,
        historySetLimit,
        historySetOffset,
    };
};

export { mapStateToProps, mapDispatchToProps };
