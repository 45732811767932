import React from 'react';
import { Table } from 'semantic-ui-react';
import ButtonRemoveFromBlacklist from '../button-remove_blacklist/button-remove_blacklist';
import { withCallcenterAPI } from '../callcenter-api';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './dispatch';
import MessageError from '../message-error';

class TableBlacklist extends React.Component {
    tableBody = data => {
        if (!data) {
            return null;
        }

        const rows = data.map(blacklistItem => {
            return (
                <Table.Row key={blacklistItem.msisdn}>
                    <Table.Cell>{blacklistItem.msisdn}</Table.Cell>
                    <Table.Cell>{blacklistItem.date_time}</Table.Cell>
                    <Table.Cell>{blacklistItem.comment}</Table.Cell>
                    <Table.Cell>
                        <ButtonRemoveFromBlacklist
                            onDataUpdate={this.onDataUpdate}
                            phone={blacklistItem.msisdn}
                        />
                    </Table.Cell>
                </Table.Row>
            );
        });

        return <Table.Body> {rows} </Table.Body>;
    };

    render() {
        const { blacklistItems, error } = this.props.callcenterBlacklist;

        if (!!error) {
            return MessageError(error);
        }
        if (!blacklistItems) return null;

        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell textAlign={'center'}>
                            Телефон
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={'center'}>
                            Дата внесения в ЧС
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={'center'}>
                            Комментарий
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign={'center'}>
                            Операции
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {this.tableBody(blacklistItems)}
            </Table>
        );
    }
}

export default withCallcenterAPI(
    connect(mapStateToProps, mapDispatchToProps)(TableBlacklist),
);
