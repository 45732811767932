const initialState = {
    historyItems: null,
    isLoading: false,
    error: null,
    offset: 0,
    limit: 10,
};

const historyReducer = (state, action) => {
    switch (action.type) {
        case 'SAVE_HISTORY':
            return {
                historyItems: action.historyItems,
                isLoading: false,
                error: null,
                offset: state.offset,
                limit: state.limit,
            };

        case 'SET_HISTORY_LOADING':
            return {
                historyItems: state.historyItems,
                isLoading: true,
                error: null,
                offset: state.offset,
                limit: state.limit,
            };

        case 'SAVE_ERROR':
            return {
                historyItems: state.historyItems,
                isLoading: false,
                error: action.error,
                offset: state.offset,
                limit: state.limit,
            };

        case 'SET_OFFSET':
            return {
                historyItems: state.historyItems,
                isLoading: false,
                error: null,
                offset: action.offset,
                limit: state.limit,
            };

        case 'SET_LIMIT':
            return {
                historyItems: state.historyItems,
                isLoading: false,
                error: null,
                offset: state.offset,
                limit: action.limit,
            };

        case 'CLEAR_HISTORY':
        default:
            return initialState;
    }
};

export default historyReducer;
