import React from 'react';
import { connect } from 'react-redux';
import { Icon, Menu, Table } from 'semantic-ui-react';
import LabelHistoryOperation from '../label-history_operation';
import MessageError from '../message-error';
import { formatDateString } from '../../services/utils/date';
import { withCallcenterAPI } from '../callcenter-api';
import { mapStateToProps, mapDispatchToProps } from './dispatch';

class TableHistory extends React.Component {
    componentDidMount() {
        const { offset, limit } = this.props.callcenterHistory;
        this.props.historyFetch(offset, limit);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { offset, limit } = this.props.callcenterHistory;
        const {
            offset: prevOffset,
            limit: prevLimit,
        } = prevProps.callcenterHistory;

        if (prevOffset !== offset || prevLimit !== limit) {
            const { offset, limit } = this.props.callcenterHistory;
            this.props.historyFetch(offset, limit);
        }
    }

    tableBody = data => {
        const rows = data.map(historyItem => {
            return (
                <Table.Row
                    key={`${historyItem.msisdn}-${historyItem.date_time}`}
                >
                    <Table.Cell>{historyItem.msisdn}</Table.Cell>
                    <Table.Cell>{historyItem.operator}</Table.Cell>
                    <Table.Cell>
                        {formatDateString(historyItem.date_time)}
                    </Table.Cell>
                    <Table.Cell>
                        <LabelHistoryOperation
                            operation={historyItem.operation}
                        />
                    </Table.Cell>
                    <Table.Cell>{historyItem.user}</Table.Cell>
                    <Table.Cell>{historyItem.comment}</Table.Cell>
                </Table.Row>
            );
        });

        return <Table.Body> {rows} </Table.Body>;
    };

    onClickNextPage = () => {
        const { offset, limit } = this.props.callcenterHistory;
        this.props.historySetOffset(offset + limit);
    };

    onClickPrevPage = () => {
        const { offset, limit } = this.props.callcenterHistory;

        if (offset > 0) {
            this.props.historySetOffset(offset - limit);
        }
    };

    render() {
        const {
            historyItems,
            isLoading,
            error,
            offset,
            limit,
        } = this.props.callcenterHistory;

        const isPrevDisabled = offset < limit;
        const isNextDisabled = !historyItems || historyItems.length < limit;
        const currentPageNum = offset / limit + 1;

        let content;

        if (error !== null) {
            content = MessageError(error);
        } else {
            content = (
                <Table celled style={{ minWidth: '1200px', minHeight: '80vh' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign={'center'}>
                                Телефон
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign={'center'}>
                                Оператор
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign={'center'}>
                                Дата операции
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign={'center'}>
                                Операция
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign={'center'}>
                                Пользователь
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign={'center'}>
                                Комментарий
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {historyItems && this.tableBody(historyItems)}
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan="7">
                                <Menu floated="right" pagination>
                                    <Menu.Item
                                        as="a"
                                        icon
                                        disabled={isPrevDisabled}
                                        onClick={this.onClickPrevPage}
                                    >
                                        <Icon name="chevron left" />
                                    </Menu.Item>
                                    <Menu.Item as="a">
                                        {currentPageNum}
                                    </Menu.Item>
                                    <Menu.Item
                                        as="a"
                                        icon
                                        disabled={isNextDisabled}
                                        onClick={this.onClickNextPage}
                                    >
                                        <Icon name="chevron right" />
                                    </Menu.Item>
                                </Menu>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            );
        }

        return content;
    }
}

export default withCallcenterAPI(
    connect(mapStateToProps, mapDispatchToProps)(TableHistory),
);
