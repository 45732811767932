import React from 'react';
import {
    Button,
    Form,
    Grid,
    Header,
    Icon,
    Message,
    Segment,
} from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { withCallcenterAPI } from '../callcenter-api';

class FormLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            isIncorrectAuth: false,
        };
    }

    onChangeEmail = event => {
        this.setState({
            email: event.target.value,
        });
    };

    onChangePassword = event => {
        this.setState({
            password: event.target.value,
        });
    };

    onClickLogin = () => {
        if (!this.state) {
            return;
        }
        const { callcenterAPI } = this.props;
        const { email, password } = this.state;

        callcenterAPI.setLogin(email);
        callcenterAPI.setPassword(password);

        this.props.callcenterAPI
            .authenticate()
            .then(result => {
                if (result === true) {
                    this.setState({
                        isIncorrectAuth: false,
                    });
                    this.props.history.push('/search');
                } else {
                    this.setState({
                        isIncorrectAuth: true,
                    });
                }
            })
            .catch(e => {
                alert(e);
            });
    };

    render() {
        const { isIncorrectAuth } = this.state;
        const { email, password } = this.state;

        const isAuthDisable = !(email && password);

        return (
            <Grid textAlign="center" verticalAlign="middle">
                <Grid.Column style={{ maxWidth: 450 }}>
                    <Header
                        as="h2"
                        style={{ color: '#55acee' }}
                        textAlign="center"
                    >
                        Войти в систему
                    </Header>
                    <Form size="large">
                        <Segment stacked>
                            <Form.Input
                                fluid
                                icon="user"
                                iconPosition="left"
                                placeholder="Login"
                                onChange={this.onChangeEmail}
                            />
                            <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Password"
                                type="password"
                                onChange={this.onChangePassword}
                            />
                            {!!isIncorrectAuth ? (
                                <Message
                                    color={'red'}
                                    header="Ошибка авторизации"
                                    content="Введен неправильный логин\пароль."
                                />
                            ) : null}
                            <Button
                                disabled={isAuthDisable}
                                color="twitter"
                                fluid
                                size="large"
                                onClick={this.onClickLogin}
                            >
                                Войти
                            </Button>
                        </Segment>
                    </Form>
                    <Message attached="bottom" warning>
                        <Icon name="help" />
                        Для получения логина и пароля свяжитесь с вашим
                        менеджером
                    </Message>
                </Grid.Column>
            </Grid>
        );
    }
}

export default withRouter(withCallcenterAPI(FormLogin));
