import React from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { mapStateToProps } from './dispatch';

const MessageJustUseBlacklist = props => {
    const { isGreetingMessage } = props.callcenterBlacklist;
    if (isGreetingMessage) {
        return (
            <Message
                info
                icon="smile outline"
                header="Воспользуйтесь чёрным списком!"
                content="Введите телефон и нажмите кнопку поиск"
            />
        );
    }

    return null;
};

export default connect(mapStateToProps)(MessageJustUseBlacklist);
