import React from 'react';
import { CallcenterAPIConsumer } from './callcenter';

const withCallcenterAPI = Wrapped => {
    return props => {
        return (
            <CallcenterAPIConsumer>
                {callcenterAPI => {
                    return <Wrapped {...props} callcenterAPI={callcenterAPI} />;
                }}
            </CallcenterAPIConsumer>
        );
    };
};

export default withCallcenterAPI;
