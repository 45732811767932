import React from 'react';
import { Container, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const Header = () => (
    <Menu fixed="top" inverted>
        <Container>
            <Menu.Item as="a" header>
                CALLCENTER
            </Menu.Item>
            {/*<Link to={'/subscription'}>*/}
            {/*    <Menu.Item as="a">Подписки</Menu.Item>*/}
            {/*</Link>*/}
            <Link to={'/search'}>
                <Menu.Item as="a">Поиск</Menu.Item>
            </Link>
            <Link to={'/blacklist'}>
                <Menu.Item as="a">Чёрный список</Menu.Item>
            </Link>
            <Link to={'/history'}>
                <Menu.Item as="a">История</Menu.Item>
            </Link>
        </Container>
    </Menu>
);

export default Header;
