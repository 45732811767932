import React from 'react';
import {
    Container,
    Form,
    Grid,
    Message,
    Table,
    TextArea,
} from 'semantic-ui-react';
import Button from 'semantic-ui-react/dist/commonjs/elements/Button';
import LabelStatusMultiplyBlacklist from '../label-status_multiply_blacklist';
import { withCallcenterAPI } from '../callcenter-api';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './dispatch';

class ContainerBlacklistMultiply extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: 'Причина не указана',
        };
    }

    onChangeText = event => {
        const textWithPhones = event.target.value;
        const phones = this.getPhonesFromText(textWithPhones);
        const phoneStructs = this.createPhoneStructs(phones);
        this.props.blacklistSave(phoneStructs);
    };

    onChangeComment = event => {
        this.setState({
            comment: event.target.value,
        });
    };

    onClickBlacklist = () => {
        const {
            blacklistItems: phoneStructs,
        } = this.props.callcenterBlacklistMultiply;
        if (!phoneStructs) return null;

        const { comment } = this.state;
        this.props.blacklistMultiply(phoneStructs, comment);
    };

    createPhoneStructs = phones => {
        if (!phones) return;
        return phones.map(phone => {
            return {
                msisdn: phone,
                status: -1,
            };
        });
    };

    getPhonesFromText = text => {
        const regexp = /\d+/g;
        return text.match(regexp);
    };

    getTableBody(phones) {
        const rows = phones.map(phone => {
            return (
                <Table.Row key={phone.msisdn}>
                    <Table.Cell>{phone.msisdn}</Table.Cell>
                    <Table.Cell>
                        {LabelStatusMultiplyBlacklist(phone.status)}
                    </Table.Cell>
                </Table.Row>
            );
        });

        return <Table.Body>{rows}</Table.Body>;
    }

    render() {
        const {
            isVisible,
            isLoading,
            blacklistItems: phoneStructs,
        } = this.props.callcenterBlacklistMultiply;
        if (!isVisible) return null;

        return (
            <Container>
                <Grid columns={2} divided>
                    <Grid.Row>
                        <Grid.Column>
                            <Form>
                                <TextArea
                                    style={{ minHeight: '300px' }}
                                    placeholder="Введите номера, которые следует добавить в чёрный список"
                                    onChange={this.onChangeText}
                                />
                                <TextArea
                                    style={{ marginTop: '1em' }}
                                    placeholder="Введите причину добавления в чёрный список"
                                    onChange={this.onChangeComment}
                                />
                            </Form>
                            <Button
                                style={{ marginTop: '1em' }}
                                onClick={this.onClickBlacklist}
                                loading={isLoading}
                            >
                                Добавить в чёрный список
                            </Button>
                        </Grid.Column>

                        <Grid.Column>
                            {!!phoneStructs ? (
                                <Table celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>
                                                Телефон
                                            </Table.HeaderCell>
                                            <Table.HeaderCell>
                                                Статус
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    {this.getTableBody(phoneStructs)}
                                </Table>
                            ) : (
                                <Message
                                    info
                                    icon="smile outline"
                                    header="Групповое добавление в чёрный список!"
                                    content="Введите текст, содержащий номера, которые следует добавить в чёрный список, а затем нажмите кнопку 'Отписать'"
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

export default withCallcenterAPI(
    connect(mapStateToProps, mapDispatchToProps)(ContainerBlacklistMultiply),
);
