import LoginForm from '../form-login/form-login';
import React from 'react';
import { Container } from 'semantic-ui-react';

const Login = () => (
    <Container style={{ marginTop: '18em' }}>
        <LoginForm />
    </Container>
);

export default Login;
