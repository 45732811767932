const initialState = {
    blacklistItems: null,
    isEmpty: false,
    isLoading: false, // данные подгружаются
    isVisible: false,
    isGreetingMessage: true,
    error: null, // ошибка, если есть
};

const blacklistMultiplyReducer = (state, action) => {
    switch (action.type) {
        case 'MULTIPLY-BLACKLIST_SAVE':
            const isEmpty =
                !action.blacklistItems || action.blacklistItems.length === 0;
            return {
                blacklistItems: action.blacklistItems,
                isEmpty: isEmpty,
                isLoading: false,
                isVisible: state.isVisible,
                isGreetingMessage: false,
                error: null,
            };

        case 'MULTIPLY-BLACKLIST_SET_LOADING':
            return {
                blacklistItems: state.blacklistItems,
                isEmpty: false,
                isLoading: action.isLoading,
                isVisible: state.isVisible,
                isGreetingMessage: false,
                error: null,
            };

        case 'MULTIPLY-BLACKLIST_SET_VISIBLE':
            return {
                blacklistItems: state.blacklistItems,
                isEmpty: false,
                isLoading: false,
                isVisible: action.isVisible,
                isGreetingMessage: false,
                error: null,
            };

        case 'MULTIPLY-BLACKLIST_SAVE_ERROR':
            return {
                blacklistItems: state.blacklistItems,
                isEmpty: false,
                isLoading: false,
                isVisible: state.isVisible,
                isGreetingMessage: false,
                error: action.error,
            };

        case 'MULTIPLY-BLACKLIST_CLEAR':
        default:
            return initialState;
    }
};

export default blacklistMultiplyReducer;
