import { combineReducers } from 'redux';
import searchReducer from './search/searchReducer';
import historyReducer from './history/historyReducer';
import blacklistReducer from './blacklist/blacklistReducer';
import blacklistMultiplyReducer from './blacklist-multiply/blacklistMutiplyReducer';

export default combineReducers({
    historyReducer,
    searchReducer,
    blacklistReducer,
    blacklistMultiplyReducer,
});
