import React from 'react';
import { connect } from 'react-redux';
import { Message } from 'semantic-ui-react';
import { mapStateToProps } from './dispatch';

const MessageBlacklistSearchNotFound = props => {
    const { phone, isEmptySearch } = props.callcenterBlacklist;

    if (isEmptySearch) {
        return (
            <Message
                info
                icon="frown outline"
                header={`Абонента ${phone} нет в чёрном списке`}
                content="Абонент отсутствует в чёрном списке"
            />
        );
    }

    return null;
};

export default connect(mapStateToProps)(MessageBlacklistSearchNotFound);
