export const blacklistSave = blacklistItems => ({
    type: 'MULTIPLY-BLACKLIST_SAVE',
    blacklistItems,
});
export const blacklistSaveError = error => ({
    type: 'MULTIPLY-BLACKLIST_SAVE_ERROR',
    error,
});
export const blacklistSetLoading = (isLoading = true) => ({
    type: 'MULTIPLY-BLACKLIST_SET_LOADING',
    isLoading,
});
export const blacklistSetVisible = (isVisible = true) => ({
    type: 'MULTIPLY-BLACKLIST_SET_VISIBLE',
    isVisible,
});
export const blacklistSetBlacklisted = (isBlacklisted = true) => ({
    type: 'BLACKLIST_SET_BLACKLISTED',
    isBlacklisted,
});
export const blacklistClear = () => ({ type: 'MULTIPLY-BLACKLIST_CLEAR' });
