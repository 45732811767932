import { Message } from 'semantic-ui-react';
import React from 'react';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import { withCallcenterAPI } from '../callcenter-api';
import { connect } from 'react-redux';
import { mapStateToProps } from './dispatch';

class MessageAddedToBlacklist extends React.Component {
    render() {
        const { isBlacklisted, phone } = this.props.callcenterBlacklist;

        let content = (
            <Message icon info>
                <Icon
                    name="sync"
                    loading
                    style={{ marginLeft: '20px', marginRight: '40px' }}
                />
                <Message.Content>
                    <Message.Header>{`Отправлен запрос на добавление ${phone} в чёрный список`}</Message.Header>
                    <Message.List>
                        <Message.Item>
                            Операция добавление в чёрный список займет некоторое
                            время (как правило не более минуты).
                        </Message.Item>
                        <Message.Item>
                            Повторите поиск чтобы, удостовериться что телефон
                            действительно был добавлен в чёрный список.
                        </Message.Item>
                        <Message.Item>
                            Пока пользователь находится в чёрном списке, он{' '}
                            <b>не может</b> оформлять новые подписки.
                        </Message.Item>
                        <Message.Item>
                            При добавлении пользователя в чёрный список, все
                            активные подписки закрываются.
                        </Message.Item>
                    </Message.List>
                </Message.Content>
            </Message>
        );

        return isBlacklisted ? content : null;
    }
}

export default withCallcenterAPI(
    connect(mapStateToProps)(MessageAddedToBlacklist),
);
