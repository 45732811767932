export const historySave = historyItems => ({
    type: 'SAVE_HISTORY',
    historyItems,
});
export const historySaveError = error => ({ type: 'SAVE_ERROR', error });
export const historySetLoading = (isLoading = true) => ({
    type: 'SET_HISTORY_LOADING',
    isLoading,
});
export const historyClear = () => ({ type: 'CLEAR_HISTORY' });
export const historySetOffset = offset => ({
    type: 'SET_OFFSET',
    offset,
});
export const historySetLimit = limit => ({
    type: 'SET_LIMIT',
    limit,
});
