import { bindActionCreators } from 'redux';
import * as actionsSearch from '../../action/search/actions';

const mapStateToProps = state => {
    return {
        callcenterSearch: state.searchReducer,
    };
};

const mapDispatchToProps = (dispatch, { callcenterAPI }) => {
    const { searchSetUnsubscribed } = bindActionCreators(
        actionsSearch,
        dispatch,
    );

    const doUnsubscribe = (subscription_id, comment) => {
        callcenterAPI.doUnsubscribe(subscription_id, comment).then(() => {
            searchSetUnsubscribed();
        });
    };

    return {
        doUnsubscribe,
    };
};

export { mapStateToProps, mapDispatchToProps };
