import { Message } from 'semantic-ui-react';
import React from 'react';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import { withCallcenterAPI } from '../callcenter-api';
import { connect } from 'react-redux';
import { mapStateToProps } from './dispatch';

class MessageUnsubscribe extends React.Component {
    render() {
        const { isUnsubscribed } = this.props.callcenterSearch;

        let content = (
            <Message icon info>
                <Icon
                    name="sync"
                    loading
                    style={{ marginLeft: '20px', marginRight: '40px' }}
                />
                <Message.Content>
                    <Message.Header>{`Отправлен запрос закрытие подписки`}</Message.Header>
                    <Message.List>
                        <Message.Item>
                            Операция закрытия подписки займет некоторое время
                            (как правило не более минуты).
                        </Message.Item>
                        <Message.Item>
                            Повторите поиск чтобы, удостовериться что подписка
                            действительно была закрыта.
                        </Message.Item>
                    </Message.List>
                </Message.Content>
            </Message>
        );

        return isUnsubscribed ? content : null;
    }
}

export default withCallcenterAPI(connect(mapStateToProps)(MessageUnsubscribe));
