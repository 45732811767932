import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Login from '../page-login';
import Search from '../page-search';
import PageBlacklist from '../page-blacklist';
import History from '../page-history';
import { CallcenterAPIProvider } from '../callcenter-api';
import CallcenterApi from '../../services/callcenter-api';
import Header from '../header';

const callcenterAPI = new CallcenterApi('', '');

const Root = ({ store }) => (
    <Provider store={store}>
        <CallcenterAPIProvider value={callcenterAPI}>
            <Router>
                <Header />
                <Route exact path="/" component={Login} />
                <Route exact path="/search" component={Search} />
                <Route exact path="/blacklist" component={PageBlacklist} />
                <Route exact path="/history" component={History} />
            </Router>
        </CallcenterAPIProvider>
    </Provider>
);

Root.propTypes = {
    store: PropTypes.object.isRequired,
};

export default Root;
