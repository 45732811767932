import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from './dispatch';

const ButtonShowBlacklistMultiply = props => {
    const { isVisible } = props.callcenterBlacklistMultiply;
    return (
        <Button
            icon
            labelPosition="right"
            color={'teal'}
            style={{
                marginLeft: '1em',
                minHeight: '60px',
            }}
            onClick={() => {
                props.blacklistSetVisible(!isVisible);
            }}
        >
            <Icon name="eye" size={'large'} />
            Добавить в ЧС
        </Button>
    );
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ButtonShowBlacklistMultiply);
