const initialState = {
    searchItems: null,
    isLoading: false,
    isUnsubscribed: false,
    isEmptySearch: false,
    isGreetingMessage: true,
    error: null,
    phone: null,
};

const searchReducer = (state, action) => {
    switch (action.type) {
        case 'SAVE_SEARCH':
            const isEmptySearch =
                !action.searchItems || action.searchItems.length === 0;
            return {
                searchItems: action.searchItems,
                isLoading: false,
                isUnsubscribed: false,
                isEmptySearch: isEmptySearch,
                isGreetingMessage: false,
                error: null,
                phone: state.phone,
            };

        case 'SET_PHONE':
            return {
                searchItems: state.searchItems,
                isLoading: false,
                isUnsubscribed: false,
                isEmptySearch: false,
                isGreetingMessage: false,
                error: null,
                phone: action.phone,
            };

        case 'SET_SEARCH_LOADING':
            return {
                searchItems: state.searchItems,
                isLoading: true,
                isUnsubscribed: false,
                isEmptySearch: false,
                isGreetingMessage: false,
                error: null,
                phone: state.phone,
            };

        case 'SET_UNSUBSCRIBED':
            return {
                searchItems: null,
                isLoading: null,
                isUnsubscribed: action.isUnsubscribed,
                isEmptySearch: false,
                isGreetingMessage: false,
                error: null,
                phone: state.phone,
            };

        case 'SAVE_ERROR':
            return {
                searchItems: state.searchItems,
                isLoading: false,
                isUnsubscribed: false,
                isEmptySearch: false,
                isGreetingMessage: false,
                error: action.error,
                phone: state.phone,
            };

        case 'CLEAR_SEARCH':
        default:
            return initialState;
    }
};

export default searchReducer;
