var options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    // weekday: 'long',
    timezone: 'UTC',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
};

function formatDateString(datestring) {
    var date;
    try {
        date = new Date(datestring);
    } catch (e) {
        console.log(e);
        return;
    }
    return date.toLocaleString('ru', options);
}

export { formatDateString };
