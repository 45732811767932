import { Message } from 'semantic-ui-react';
import React from 'react';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import { withCallcenterAPI } from '../callcenter-api';
import { connect } from 'react-redux';
import { mapStateToProps } from './dispatch';

class MessageAlreadyInBlacklist extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDisabled: true,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { phone } = this.props.callcenterSearch;
        const { phone: prevPhone } = prevProps.callcenterSearch;
        const { callcenterAPI } = this.props;

        if (!phone || phone === prevPhone) return null;

        callcenterAPI
            .doSearchInBlacklist(phone)
            .then(result => {
                if (result.length > 0) {
                    this.setState({ isDisabled: false });
                }
            })
            .catch(() => {
                this.setState({ isDisabled: true });
            });
    }

    render() {
        const { phone } = this.props.callcenterSearch;

        let content = (
            <Message icon negative>
                <Icon name="eye" />
                <Message.Content>
                    <Message.Header>{`Телефон ${phone} находиться в чёрном списке`}</Message.Header>
                    <Message.List>
                        <Message.Item>
                            Пока пользователь находится в чёрном списке, он{' '}
                            <b>не может</b> оформлять новые подписки.
                        </Message.Item>
                        <Message.Item>
                            При добавлении пользователя в чёрный список, все
                            активные подписки закрываются.
                        </Message.Item>
                    </Message.List>
                </Message.Content>
            </Message>
        );

        return this.state.isDisabled ? null : content;
    }
}

export default withCallcenterAPI(
    connect(mapStateToProps)(MessageAlreadyInBlacklist),
);
