import { Container } from 'semantic-ui-react';
import React from 'react';
import BlacklistButtons from '../buttonpanel-blacklist/buttonpanel-blacklist';
import TableBlacklist from '../table-blacklist/table';
import MessageAddedToBlacklist from '../message-added_to_blacklist';
import MessageJustUseBlacklist from '../message-just_use_blacklist';
import MessageBlacklistSearchNotFound from '../message-blacklist_search_not_found';
import MessageRemoveBlacklist from '../message-remove_from_blacklist';
import ContainerBlacklistMultiplyAdd from '../container-blacklist_multiply/container-blacklist-multuply';
import ButtonShowBlacklistMultiply from '../button-show_blacklist_multiply_container';

export default class PageBlacklist extends React.Component {
    render() {
        return (
            <Container style={{ marginTop: '4em' }}>
                <Container textAlign={'center'}>
                    <BlacklistButtons />
                    <ButtonShowBlacklistMultiply />
                </Container>
                <MessageJustUseBlacklist />
                <MessageAddedToBlacklist />
                <MessageRemoveBlacklist />
                <MessageBlacklistSearchNotFound />
                <ContainerBlacklistMultiplyAdd />
                <TableBlacklist />
            </Container>
        );
    }
}
