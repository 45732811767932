import { Container } from 'semantic-ui-react';
import React from 'react';
import TableHistory from '../table-history/table';

const History = () => (
    <Container style={{ marginTop: '4em' }}>
        <TableHistory />
    </Container>
);

export default History;
