import { bindActionCreators } from 'redux';
import * as actionsBlacklist from '../../action/blacklist/actions';

const mapStateToProps = state => {
    return {
        callcenterBlacklist: state.blacklistReducer,
    };
};

const mapDispatchToProps = (dispatch, { callcenterAPI }) => {
    const {
        blacklistClear,
        blacklistSave,
        blacklistSaveError,
        blacklistSetLoading,
        blacklistSetPhone,
    } = bindActionCreators(actionsBlacklist, dispatch);

    const blacklistFetch = phone => {
        blacklistSetLoading(true);

        callcenterAPI
            .doSearch(phone)
            .then(data => {
                blacklistSave(data);
            })
            .catch(e => {
                blacklistSaveError(e);
            });
    };

    return {
        blacklistSetPhone,
        blacklistFetch,
        blacklistClear,
    };
};

export { mapStateToProps, mapDispatchToProps };
