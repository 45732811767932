import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { withCallcenterAPI } from '../callcenter-api';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from './dispatch';

class ButtonRemoveBlacklist extends React.Component {
    onClickHandler = () => {
        const { phone } = this.props;
        this.props.blacklistRemove(phone);
    };

    render() {
        return (
            <Button
                icon
                labelPosition="left"
                size={'tiny'}
                color={'teal'}
                onClick={this.onClickHandler}
            >
                <Icon size={'large'} name={'eye slash'} />
                Убрать из ЧС
            </Button>
        );
    }
}

export default withCallcenterAPI(
    connect(mapStateToProps, mapDispatchToProps)(ButtonRemoveBlacklist),
);
