import { Message } from 'semantic-ui-react';
import React from 'react';

const MessageError = error => {
    return (
        <Message
            negative
            icon="bug"
            header="Произошла ошибка, пожалуйста обратитесь в службу поддержки!"
            content={`${error.name} : ${error.message}`}
        />
    );
};

export default MessageError;
