import { Label } from 'semantic-ui-react';
import React from 'react';

const LabelStatusSubscription = ({ status }) => {
    let result;

    switch (status) {
        case 'sub_created':
            result = (
                <Label
                    color={'violet'}
                    title={'подписка создана, но пока не активирована'}
                >
                    Создана
                </Label>
            );
            break;
        case 'sub_active':
            result = (
                <Label
                    color={'green'}
                    title={
                        'подписка оформлена и активна, подразумеваются постоянные ребиллинги'
                    }
                >
                    Активна
                </Label>
            );
            break;
        case 'sub_suspended':
            result = (
                <Label
                    color={'blue'}
                    title={
                        'подписка заморожена, не подразумевает постоянных ребиллингов до разморозки'
                    }
                >
                    Заморожена
                </Label>
            );
            break;
        case 'sub_ended':
            result = (
                <Label
                    color={'olive'}
                    title={
                        'подписка окончена, например, пользователь отписался'
                    }
                >
                    Окончена
                </Label>
            );
            break;
        case 'sub_error':
            result = (
                <Label
                    color={'red'}
                    title={'не удалось оформить подписку из-за ошибки'}
                >
                    Ошибка
                </Label>
            );
            break;
        default:
            result = <Label>Не известно</Label>;
    }
    return result;
};

export default LabelStatusSubscription;
